<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import introJs from '@/shared/intro.js';
import {nl2br} from '@/shared/utils.js'
import moment from 'moment'
import {isMobile} from '@/shared/utils.js'
import Multiselect from 'vue-multiselect'

export default {
  name: "Details",
  components: { Multiselect },
  data: function () {
    return {
      nl2br: nl2br,
      moment: moment,
      accounts: [],
      originalParticipantsCount: 0,
      event: {
        id: 0,
        participants: [],
        consultants: [],
        prices: [],
        location: {},
      },
    }
  },
  computed: {
    isMobile () {
      return isMobile();
    },
    maxSlots: function () {
      return this.event.slots + this.originalParticipantsCount;
    },
    coupon_total: function () {
      let self = this;
      let coupon = 0;
      self.event.participants.forEach(participant => {
        if(parseFloat(participant.coupon.value) > 0){
          coupon += parseFloat(participant.coupon.value);
        }
      })
      return coupon;
    },
    price_total: function () {
      let self = this;
      let price_total = 0;
      if(self.event.prices) {
        self.event.prices.forEach(price => {
          price_total += price.count * price.price;
          price_total += price.count * price.fixed;
        })
      }
      return price_total;
    },
  },
  created: function(){
    let self = this;
    if (self.$route.params.id > 0) {
      Http.request('GET', '/events/' + self.$route.params.id).then(function (response) {
        self.originalParticipantsCount = response.data.participants ? response.data.participants.length : 0;
        self.event = response.data;

        if(self.event.prices !== null) {
          self.event.prices.forEach(price => {
            self.$set(price, 'count', 0);
          });
        }

        Http.request('GET', '/accounts').then(function (response) {
          response.data.forEach(account => {
            let account2 = self.event.participants.find(function (participant) {
              return participant.account.id === account.id;
            });
            if(account2){
              account = account2.account;
            }

            self.accounts.push({
              account: account,
            });
          })
        });
      });
    }

    introJs.setOption('steps', [
      {
        element: '[data-intro-step="1"]',
        intro: "Hier findest du weitere Details zum Event und kannst deine Teilnehmer*innen für die Buchung zuweisen.",
      },
      {
        element: '[data-intro-step="2"]',
        intro: "Um nähere Informationen zu einem Thema zu erhalten, klicke einfach auf die Boxen.",
      },
      {
        element: '[data-intro-step="3"]',
        intro: "Alle Teilnehmer*innen findest du in diesem Bereich. Mit Klick auf die Box, kannst du bestimmen, dass für die Person ein Platz gebucht wird. Pro Person kannst du außerdem einen Gutschein-Code eingeben.",
        position: 'top',
      },
      {
        element: '[data-intro-step="4"]',
        intro: "Die ausgewählten Personen und eingegebenen Gutscheine werden automatisch in der Tabelle einsortiert, sodass du den Gesamtpreis siehst.",
      },
      {
        element: '[data-intro-step="5"]',
        intro: "Bist du zufrieden mit allem? Dann kannst du auf &quot;Jetzt verbindlich buchen&quot; klicken, oder die Tour beenden",
      },
    ]);

    if(introJs._currentStep > 0){
      setTimeout(function () {
        introJs.exit();
        introJs.firstLoad('events', true);
      }, 1000);
    }

    introJs.onbeforechange(function(targetElement) {
      // override onbeforechange to prevent trigger from parent
    });
  },
  watch: {
    'event.participants': function(to, from) {
      let self = this;

      if(self.event.prices !== null){
        self.event.prices.forEach(price => {
          self.$set(price, 'count', 0);
        })
      }

      self.event.participants.forEach(participant => {
        if(!participant.certification){
          participant.certification = 0;
        }
        if(!participant.coupon){
          self.$set(participant, 'coupon', {});
        }

        if(self.event.prices[0].limit && self.event.prices[0].limit > self.event.prices[0].count){
          self.event.prices[0].count += 1;
        }
        else if(self.event.prices.length === 2) {
          self.event.prices[1].count += 1;
        }
        else {
          self.event.prices[0].count += 1;
        }
      });
    }
  },
  methods: {
    accountName(participant){
      return participant.account.name;
    },
    saveParticipants: function () {
      let self = this;
      let participants = [];
      self.event.participants.forEach(participant => {
        participants.push({
          account: participant.account,
          certification: participant.certification,
          coupon: participant.coupon.code,
        });
      });

      Http.request('POST', '/events/' + self.$route.params.id + '/participate', {participants: participants}).then(function (response) {
        self.accounts = response.data;
        setTimeout(function () {
          self.$bvToast.toast('Deine Buchung war erfolgreich', {
            title: 'Vielen Dank',
            variant: 'success',
            autoHideDelay: 5000
          })
        }, 2000)
        self.$router.push("/events");
      });
    },
    checkCouponInput: function (participant) {
      let self = this;
      if(participant.coupon !== null && participant.coupon.code.length === 9){
        Http.request('POST', '/events/' + self.$route.params.id + '/coupon', {code: participant.coupon.code}).then(function (response) {
          participant.coupon = response.data;
        })
        .catch(function (error) {
          participant.coupon.code = null;
        });
      }
    },
    checkCouponChange: function (participant) {
      if(participant.coupon !== null && participant.coupon.code.length !== 9){
        participant.coupon.code = null;
        this.$bvModal.msgBoxOk('Der Gutschein Code muss 9 stellen lang sein');
      }
    }
  }
}
</script>

<style scoped>
</style>
